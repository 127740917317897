import React, { useState, useRef } from "react"
import VisibilitySensor from "react-visibility-sensor"
import CountUp from "react-countup"
import BackgroundImage from "gatsby-background-image-es5"
import { StaticImage } from "gatsby-plugin-image"
import { useStaticQuery, graphql } from "gatsby"
import { Carousel } from "react-responsive-carousel"
import scrollTo from "gatsby-plugin-smoothscroll"
import { useFormspark } from "@formspark/use-formspark"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Video from "../components/video"

const IndexPage = () => {
  const data = useStaticQuery(
    graphql`
      query {
        desktop: file(relativePath: { eq: "bba.png" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `
  )

  const [visible, setVisible] = useState(false)
  const [isSuccess, setIsSuccess] = useState(false)

  const nameRef = useRef()
  const emailRef = useRef()
  const messageRef = useRef()

  const [submit, submitting] = useFormspark({
    formId: "GOxTk5XL",
  })

  const onChangeVisibility = isActive => {
    setVisible(isActive)
  }

  const contactFormSubmitted = async event => {
    event.preventDefault()
    await submit({
      name: nameRef.current.value,
      email: emailRef.current.value,
      message: messageRef.current.value,
    })
    setIsSuccess(true)
  }

  const imageData = data.desktop.childImageSharp.fluid

  return (
    <Layout>
      <Seo title="Personal Trainer" />
      <section className="relative pt-32 md:pt-56 pb-32 bg-texture bg-no-repeat bg-cover bg-blend-overlay">
        <div className="flex flex-row justify-center items-center max-w-screen-lg mx-auto px-6 md:px-0">
          <div className="w-100 text-center md:text-left mr-auto">
            <h1 className="text-3xl md:text-4xl text-white font-bold m-0 tracking-wide">
              Personal Training
            </h1>
            <div className="text-green-400 mt-2">By Christiana Biba</div>
            <h2 className="text-sm2 leading-5 text-gray-100 font-light mt-8">
              I help people improve the quality of their life through my
              passion, fitness training. From beginners to more advanced, I will
              create a personalized plan that aligns with your fitness goals and
              assist you towards achieving them
            </h2>
            <button
              className="font-body border-8 border-neon-blue bg-neon-blue text-white font-bold rounded-full py-2 px-3 shadow-button-shadow tracking-wider hover:tracking-widest	mt-6 transition duration-500 ease-in-out transform hover:scale-x-110"
              onClick={() => scrollTo("#contact")}
            >
              BOOK A FREE LESSON
            </button>
          </div>
          <div className="hidden md:block w-80 transform scale-110 mr-auto">
            <BackgroundImage
              Tag="section"
              style={{ width: "500px", height: "500px" }}
              fluid={imageData}
            />
          </div>
        </div>
        <div className="absolute bg-divider h-10 md:h-20 -bottom-1 w-full"></div>
      </section>
      {/* About Me */}
      <section id="about" className="mt-16">
        <h2 className="font-bold text-3xl text-center">About Me</h2>
        <div className="h-1 w-12 bg-navy-blue mx-auto mt-10 mb-16" />
        <div className="flex flex-col md:flex-row md:max-w-screen-lg mx-auto px-12 md:px-0">
          <div className="md:mr-10">
            <StaticImage
              src="../images/hi.png"
              className="shadow-image-shadow self-center mb-12 md:mb-0 border rounded"
              alt="Personal Trainer"
              layout="constrained"
              width={510}
            />
          </div>
          <div className="text-sm2 text-gray-600 w-100 max-w-full">
            <p>
              Hi! My name is <span className="font-bold"> Christiana</span> and
              I am a NASM-certified (CPT) personal fitness instructor. I have
              multiple years of experience, having trained people of all gender
              and ages. Fitness and coaching are my passions and I love training
              people and helping them achieve their goals and literally watch
              them change their lives.
            </p>
            <p>
              If you’re looking to take the first step towards a more healthy
              lifestyle, book a free first lesson with me where we’ll discuss
              your goals and go through the basics.
            </p>
            <p>
              <i>
                I am currently located in the beautiful{" "}
                <span className="font-bold">Leiden</span>, but I’ve worked with
                people all around the Randstad area. Online coaching is also an
                option in case you prefer your place’s comfort.
              </i>
            </p>

            <button
              className="font-body border-8 border-neon-blue bg-neon-blue text-white font-bold rounded-full py-2 px-3 shadow-button-shadow tracking-wider hover:tracking-widest	mt-6 transition duration-500 ease-in-out transform hover:scale-x-110"
              onClick={() => scrollTo("#contact")}
            >
              BOOK A FREE LESSON
            </button>
          </div>
        </div>
        <div className="flex flex-col md:flex-row md:justify-around shadow-all w-10/12 md:w-full max-w-screen-lg mx-auto py-8 px-3 mt-24">
          <VisibilitySensor
            partialVisibility
            offset={{ bottom: 50 }}
            onChange={e => onChangeVisibility(e)}
            active={!visible}
          >
            {({ isVisible }) => (
              <>
                <StatisticsItem
                  isVisible={isVisible}
                  value={50}
                  label="Happy Customers"
                />
                <StatisticsItem
                  isVisible={isVisible}
                  value={228}
                  label="Personal Classes"
                />
                <StatisticsItem
                  isVisible={isVisible}
                  value={350}
                  label="Group Classes"
                />
              </>
            )}
          </VisibilitySensor>
        </div>
      </section>
      {/* Services */}
      <section id="services" className="mt-32">
        <h2 className="font-bold text-3xl text-center">Services</h2>
        <div className="h-1 w-12 bg-navy-blue mx-auto mt-10 mb-16" />
        <div className="flex flex-col md:flex-row md:justify-between items-center md:items-baseline md:max-w-screen-lg mx-auto">
          <div className="flex flex-col w-10/12	md:w-52 mb-12 md:mb-0">
            <StaticImage
              src="../images/weightlifter.png"
              className="self-center"
              alt="Heart"
              layout="fixed"
              width={56}
              height={56}
            />
            <p className="mt-6 mb-2 font-bold">General Fitness Training</p>
            <span className="font-medium text-xs text-gray-500 leading-relaxed">
              No specific fitness goals? No problem. My personalized plans will
              make sure that you get more fit and improve your overall physical
              and mental health
            </span>
          </div>
          <div className="flex flex-col w-10/12	md:w-52 mb-12 md:mb-0">
            <StaticImage
              src="../images/exercise.png"
              className="self-center"
              alt="Muscle Toning"
              layout="fixed"
              width={56}
              height={56}
            />
            <p className="mt-6 mb-2 font-bold">Muscle Toning</p>
            <span className="font-medium text-xs text-gray-500 leading-relaxed">
              Looking to get your muscles more defined? I will assist and guide
              you with exercises that will result in exactly that outcome
            </span>
          </div>
          <div className="flex flex-col w-10/12	md:w-52 mb-12 md:mb-0">
            <StaticImage
              src="../images/shorts.png"
              className="self-center"
              alt="Weight Loss"
              layout="fixed"
              width={56}
              height={56}
            />
            <p className="mt-6 mb-2 font-bold">Weight Loss</p>
            <span className="font-medium text-xs text-gray-500 leading-relaxed">
              If you are looking to lose the extra weight, I can create a plan
              that targets your weak spots and help you achieve your desired
              physique
            </span>
          </div>
          <div className="flex flex-col w-10/12	md:w-52">
            <StaticImage
              src="../images/handsup.png"
              className="self-center"
              alt="Posture Correction"
              layout="fixed"
              width={56}
              height={56}
            />
            <p className="mt-6 mb-2 font-bold">Posture Correction</p>
            <span className="font-medium text-xs text-gray-500 leading-relaxed">
              Sitting many hours in front of your laptop or working in a job
              that has damaged your posture? I can help you improve your posture
              and get your body back to normal
            </span>
          </div>
        </div>
      </section>
      {/* Video */}
      <section className="mt-32 relative mx-auto w-10/12 h-44 md:w-660px md:h-380px">
        <Video
          videoSrcURL="https://www.youtube.com/embed/s0ualYfCYcU"
          videoTitle="Christiana Zumba"
        />
      </section>
      {/* Happy Customers */}
      <section id="customers" className="mt-32">
        <div className="pt-16 pb-8 bg-gray-yellow">
          <h2 className="font-bold text-3xl text-center">Happy Customers</h2>
          <div className="h-1 w-12 bg-navy-blue mx-auto mt-10 mb-16" />
          <Carousel
            showThumbs={false}
            interval={4500}
            infiniteLoop
            autoPlay
            showArrows={false}
            showStatus={false}
            dynamicHeight={false}
          >
            <div className="text-xl md:text-3xl max-w-screen-lg mx-auto leading-10">
              "I've been training with Christiana for more than a year and I
              totally recommend her. She is passionate with what she does and
              motivates you to keep going."
              <div className="mt-10 text-base font-semibold text-navy-blue">
                Barbara F.
              </div>
            </div>
            <div className="text-xl md:text-3xl max-w-screen-lg mx-auto leading-10">
              "Christiana is a great coach but also an amazing person! She is
              always with a smile on her face and pushes you to overcome your
              limits. Highly recommend!"
              <div className="mt-10 text-base font-semibold text-navy-blue">
                Anna A.
              </div>
            </div>
            <div className="text-xl md:text-3xl max-w-screen-lg mx-auto leading-10">
              "I love practicing with Christiana. Attentive to people's needs
              and mood, precise in the instructions, humble and fun!"
              <div className="mt-10 text-base font-semibold text-navy-blue">
                Erik v.A.
              </div>
            </div>
          </Carousel>
        </div>
      </section>
      {/* Request a free consultation */}
      <section id="contact" className="mt-32 mb-16">
        <h2 className="font-bold text-3xl text-center">
          Request a free consultation
        </h2>
        <div className="h-1 w-12 bg-navy-blue mx-auto mt-10 mb-16" />
        <form
          className="w-10/12 md:w-full max-w-screen-lg mx-auto shadow-all p-8 my-4 md:px-12 lg:px-20"
          onSubmit={contactFormSubmitted}
        >
          <div>
            <p className="text-center text-purple-800">
              Don't wait for tomorrow!
            </p>
            <p className="text-center text-sm md:text-base text-gray-600">
              Fill in the contact form and I will get back to you within 24
              hours, so that we schedule your first class!
            </p>
          </div>
          <div className="grid grid-cols-1 gap-5 md:grid-cols-2 mt-5">
            <input
              className="w-full bg-gray-100 text-gray-900 mt-2 p-3 focus:outline-none focus:shadow-outline"
              type="text"
              ref={nameRef}
              placeholder="Name*"
              required
            />
            <input
              className="w-full bg-gray-100 text-gray-900 mt-2 p-3 focus:outline-none focus:shadow-outline"
              type="email"
              ref={emailRef}
              placeholder="Email*"
              required
            />
          </div>
          <div className="my-4">
            <textarea
              name="Message"
              placeholder="Message*"
              className="w-full h-32 bg-gray-100 text-gray-900 mt-2 p-3 focus:outline-none focus:shadow-outline"
              ref={messageRef}
              required
            ></textarea>
          </div>
          <div className="flex items-center">
            <div className="my-2 w-full md:w-1/2 lg:w-1/4 md:mr-10">
              <button
                type="submit"
                className="uppercase text-sm font-bold tracking-wide bg-neon-blue hover:shadow-lg text-white p-3 rounded w-full 
                      focus:outline-none focus:shadow-outline"
              >
                Send Message
              </button>
            </div>
            {isSuccess && (
              <div className="text-green-600">
                Your message has been received!
              </div>
            )}
          </div>
        </form>
        <div className="text-gray-600 mt-12 tracking-wider text-center">
          <div>Leiden</div>
          <div className="mt-4">xristianabib@gmail.com</div>
        </div>
      </section>
    </Layout>
  )
}

const StatisticsItem = ({ isVisible, value, label }) => (
  <div className="flex flex-col items-center mb-12 md:mb-0">
    <span className="text-6xl text-navy-blue">
      <div>
        {isVisible ? (
          <CountUp
            start={1}
            end={value}
            duration={4}
            useEasing
            preserveValue
            suffix="+"
          />
        ) : (
          0
        )}
      </div>
    </span>
    <span className="mt-6">{label}</span>
  </div>
)

export default IndexPage
